import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function Header() {
  const location = useLocation();

  const handleActive = (e) => {
    document.querySelectorAll(".main-menu ul li").forEach((el) => {
      el.classList.remove("active");
    });
    e.target.parentNode.classList += " active";
  };
  const subActive = (e) => {
    document.querySelectorAll(".main-menu ul li").forEach((el) => {
      el.classList.remove("active");
    });
    e.target.parentNode.parentNode.parentNode.classList += " active";
  };

  useEffect(() => {
    // Update active class based on the current URL path
    document.querySelectorAll(".main-menu ul li").forEach((el) => {
      const link = el.querySelector("a").getAttribute("href");
      if (link === location.pathname) {
        el.classList.add("active");
      } else {
        el.classList.remove("active");
      }
    });
  }, [location]);

  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }
    if ($(".mobile-menu").length) {
      var mobileMenuContent = $(".menu-area .main-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );
      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".menu-backdrop, .mobile-menu .close-btn").on("click", function () {
        $("body").removeClass("mobile-menu-visible");
      });
    }

    $(".navbar-toggle").on("click", function () {
      $(".navbar-nav").addClass("mobile_menu");
    });
    $(".navbar-nav li a").on("click", function () {
      $(".navbar-collapse").removeClass("show");
    });

    $(".header-search > a").on("click", function () {
      $(".search-popup-wrap").slideToggle();
      return false;
    });

    $(".search-close").on("click", function () {
      $(".search-popup-wrap").slideUp(500);
    });
  }, []);

  return (
    <header>
      <div id="sticky-header" className="menu-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler">
                <i className="fas fa-bars" />
              </div>
              <div className="menu-wrap">
                <nav className="menu-nav show">
                  <div className="logo">
                    <Link to="/">
                      {/* <img src="/img/logo/logo.png" alt="" /> */}
                      <img src="/img/logo/logo.png" alt="Logo" />
                    </Link>
                  </div>
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li className="active menu-item-has-children">
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about" onClick={(e) => handleActive(e)}>
                          About
                        </Link>
                      </li>
                      <li>
                        <Link to="/services" onClick={(e) => handleActive(e)}>
                          Services
                        </Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="/blogs" onClick={(e) => handleActive(e)}>
                          Blog
                        </Link>
                      </li>
                      <li>
                        <Link to="/contacts" onClick={(e) => handleActive(e)}>
                          contacts
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="header-action d-none d-md-block">
                    <ul>
                      <li className="header-btn">
                        <Link to="/contacts" className="btn">
                          Get in Touch
                          <img src="/img/icon/w_pawprint.png" alt="" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn">
                    <i className="fas fa-times" />
                  </div>
                  <div className="nav-logo">
                    <Link to="/">
                      <img src="/img/logo/logo.png" alt="" title="true" />
                    </Link>
                  </div>
                  <div className="menu-outer"></div>
                  <div className="social-links">
                    <ul className="clearfix">
                      <li>
                        <a
                          href="https://www.reddit.com/user/Sysivit/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-reddit" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://x.com/sysivit"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/@Sysivit"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-youtube" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.pinterest.com/sysivit/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-pinterest" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop" />
            </div>
          </div>
        </div>
        <div
          className="header-shape"
          style={{ backgroundImage: "url('/img/bg/header_shape.png')" }}
        />
      </div>

      {/* <div
        className="search-popup-wrap"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="search-close">
          <span>
            <i className="fas fa-times" />
          </span>
        </div>
        <div className="search-wrap text-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="title">... Search Here ...</h2>
                <div className="search-form">
                  <form>
                    <input
                      type="text"
                      name="search"
                      placeholder="Type keywords here"
                    />
                    <button className="search-btn">
                      <i className="fas fa-search" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </header>
  );
}

export default Header;
