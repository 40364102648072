import React from "react";
import BlogBreadcumb from "../components/Error/ErrorBreadcumb";
import ErrorContent from "../components/Error/ErrorContent";

// import "../css/bootstrap.min.css";

const ErrorPage = () => {
  return (
    <main>
      <BlogBreadcumb />
      <ErrorContent />
    </main>
  );
};

export default ErrorPage;
