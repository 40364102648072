import React, { useEffect } from "react";
import "magnific-popup";
import $ from "jquery";

function Faq({ afterElment }) {
  useEffect(() => {
    /* magnificPopup video view */
    $(".popup-video").magnificPopup({
      type: "iframe",
    });
  }, []);

  return (
    <section className={afterElment + " faq-bg"}>
      <div className="container">
        <div className="row align-items-center">
          {/* <div className="col-lg-6">
          <div className="faq-img-wrap">
            <img src="/img/images/faq_tv.png" className="img-frame" alt="" />
            <img src="/img/images/faq_img.png" className="main-img" alt="" />
            <Link to="/https://www.youtube.com/watch?v=XdFfCPK5ycw" className="popup-video" > </Link>
          </div>
        </div> */}
          <div className="col-lg-12">
            <div className="faq-wrapper">
              <div className="section-title mb-35">
                <h5 className="sub-title">FAQs</h5>
                <h2 className="title">Pawsitively Helpful Insights Here</h2>
              </div>
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        For what types of pets do you provide support?
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      We offer support for a variety of pets, including cats,
                      dogs, small mammals, and birds. All of our services are
                      specially designed to meet unique requirements of your
                      pets.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Will you provide emergency pet support services?
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Yes, we do provide emergency services for your pets. If
                      you are facing any urgent needs for pet support, please
                      contact us immediately, and we will get back to you as
                      soon as possible.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        What are the qualifications of your staff?
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Our team of certified pet support professionals has
                      extensive experience in animal grooming, well-being, and
                      training management. They provide your pets with
                      comprehensive support including walking, feeding, and
                      various other activities.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-shape">
        <img src="/img/images/faq_shape.png" alt="" />
      </div>
    </section>
  );
}

export default Faq;
