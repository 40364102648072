import React from "react";
import ShopDetailsBreadcumb from "../components/dogwalking/ShopDetailsBreadcumb";
import ShopDetailsArea from "../components/dogwalking/ShopDetailsArea";

function ShopDetailsPage() {
  return (
    <main>
      <ShopDetailsBreadcumb />
      <ShopDetailsArea />
    </main>
  );
}
export default ShopDetailsPage;
