import React from "react";
import CookiePost from "../components/cookiedetails/CookiePost";
import Cookies from "../components/cookiedetails/Cookies";
import { Helmet } from "react-helmet";

function BlogDetailsPage() {
  return (
    <main>
      <Helmet>
        <title>Our Cookies Policy | Sysivit</title>
        <meta
          name="description"
          content="Explore how we work on boosting the user experience of our website visitors through this cookies policy."
        />
      </Helmet>
      <Cookies />
      <CookiePost />
    </main>
  );
}

export default BlogDetailsPage;
