import React from "react";
import BreadCumb from "../components/servicelists/BreadCumb";
import AdoptionShop from "../components/AdoptionShop";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

function DogList() {
  return (
    <main>
      <Helmet>
        <title>Our Comprehensive Pet Support Services | Sysivit</title>
        <meta
          name="description"
          content="Our wide range of quality pet management services is designed to meet all of your pet's needs and preferences."
        />
      </Helmet>
      <BreadCumb />
      <AdoptionShop />
      <Faq afterElment="faq-area-two" />
      {/* <BreedServices /> */}
      {/* <NewsLetter/> */}
    </main>
  );
}

export default DogList;
