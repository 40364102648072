// src/components/ErrorPage.js
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const ErrorPage = ({
  errorCode = "404",
  message = "Oops! Page not found.",
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const errorContainerStyle = {
    textAlign: "center",
    // margin: "100px",
    padding: "150px",
    // border: "1px solid #ccc",
    borderRadius: "8px",
    // backgroundColor: "#f9f9f9",
  };

  const errorTitleStyle = {
    fontSize: "150px",
    color: "#ff4d4d",
  };

  const errorMessageStyle = {
    fontSize: "20px",
  };

  const buttonStyle = {
    marginTop: "20px",
    padding: "10px 20px",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: isHovered ? "grey" : "#ff4d4d",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    textDecoration: "none",
  };

  return (
    <div style={errorContainerStyle}>
      <Helmet>
        <title>404 Page not found | Sysivit</title>
        <meta
          name="description"
          content="The page your trying to find is not found."
        />
      </Helmet>
      <h1 style={errorTitleStyle}>{errorCode}</h1>
      <p style={errorMessageStyle}>{message}</p>
      <Link
        to="/"
        style={buttonStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        Go back to Home
      </Link>
    </div>
  );
};

export default ErrorPage;
