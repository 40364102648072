import React from "react";

function ContactAddress() {
  return (
    <div className="col-xl-5 col-lg-6 col-md-8">
      <div className="contact-info-wrap">
        <div className="contact-img">
          <img src="/img/images/contact_img.png" alt="" />
        </div>
        <div className="contact-info-list">
          <ul>
            <li>
              <div className="icon">
                <i className="fas fa-map-marker-alt" />
              </div>
              <div className="content">
                <p>
                  351 W 57th St, New York,
                  <br /> NY 10019, USA
                </p>
              </div>
            </li>
            <li>
              <div className="icon">
                <i className="fas fa-phone-alt" />
              </div>
              <div className="content">
                <p>+1 2543673859</p>
              </div>
            </li>
            <li>
              <div className="icon">
                <i className="fas fa-envelope-open" />
              </div>
              <div className="content">
                <p>info@sysivit.com</p>
              </div>
            </li>
          </ul>
        </div>

        <div className="contact-social">
          <ul>
            <li>
              <a
                href="https://www.reddit.com/user/Sysivit/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-reddit" />
              </a>
            </li>
            <li>
              <a href="https://x.com/sysivit" target="_blank" rel="noreferrer">
                <i className="fab fa-twitter" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@Sysivit"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-youtube" />
              </a>
            </li>
            <li>
              <a
                href="https://www.pinterest.com/sysivit/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-pinterest" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ContactAddress;
