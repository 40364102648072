import React from "react";

function BlogPost() {
  return (
    <section className="blog-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="standard-blog-item mb-50">
              <div className="standard-blog-content blog-details-content">
                <p>
                  Sysivit respects your privacy and is committed to protecting
                  your personal information and ensuring that it will be handled
                  in a way that is consistent with the applicable data
                  protection law, including the Federal Trade Commission Act
                  (FTC Act) of the the USA. This Privacy Policy describes how we
                  collect, use, and protect your information and guidelines on
                  children's use of our website.
                </p>

                <h4 style={{ paddingTop: "20px" }}>1. Definitions</h4>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    &#x2022;
                    <b> Personal Information: </b>
                    This refers to any information that may be used to identify
                    you as an individual. It includes but is not limited to your
                    name, email address, phone number, and pet details.
                  </li>
                  <li>
                    &#x2022;
                    <b> Service: </b>
                    The pet support services that we offer include pet sitting,
                    dog walking, grooming assistance, training support, and
                    more.
                  </li>
                  <li>
                    &#x2022;
                    <b> Website: </b>
                    The online platform given by Sysivit through which you can
                    access our services and information.
                  </li>
                </ul>

                <h4 style={{ paddingTop: "20px" }}>
                  2. Information We Collect
                </h4>
                <p style={{ margin: 0, padding: 0 }}>
                  When you visit our website, interact with it, or use our
                  services, we may collect the following kinds of information:
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    &#x2022;
                    <b> Personal Information: </b>
                    We gather such information that you give us directly, for
                    example when you contact us or demand our services.
                  </li>
                  <li>
                    &#x2022;
                    <b> Automatic Collection: </b>
                    We also gather information about your activities on our
                    website, such as the IP address, browser type, pages
                    visited, and time spent on our website. This information
                    helps us enhance our services and make it a better
                    experience for you.
                  </li>
                </ul>

                <h4 style={{ paddingTop: "20px" }}>
                  3. How We Use Your Information
                </h4>
                <p style={{ margin: 0, padding: 0 }}>
                  We use your personal information for the following:
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    &#x2022;
                    <b> To Offer Services: </b>
                    We use your information to facilitate the delivery of our
                    pet support services and to contact you about bookings,
                    queries, or updates.
                  </li>
                  <li>
                    &#x2022;
                    <b> To Enhance Our Services: </b>
                    We may analyze the data collected to understand how our
                    services are used and identify areas for improvement.
                  </li>
                  <li>
                    &#x2022;
                    <b> To Communicate with You: </b>
                    We may send you important updates or information regarding
                    your account or services.
                  </li>
                </ul>

                <h4 style={{ paddingTop: "20px" }}>
                  4. Data Protection and Security
                </h4>
                <p style={{ margin: 0, padding: 0 }}>
                  We honor your privacy and employ reasonable practices to
                  safeguard your information, which include the following key
                  measures:
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    &#x2022;
                    <b> Access Control: </b>
                    Ensures proper authorized access only.
                  </li>
                  <li>
                    &#x2022;
                    <b> Encryption Data: </b>
                    The key processes of protecting sensitive data moving within
                    a network via the Internet and across the public
                    infrastructure that could be under third parties or
                    different jurisdictions' supervision.
                  </li>
                  <li>
                    &#x2022;
                    <b> Regular Reviewing Practice: </b>
                    Our reviews take place regularly on current applicable
                    safety practices ensuring and implementing changes in
                    relation to proper standards of related safety standards at
                    all times.
                  </li>
                </ul>

                <h4 style={{ paddingTop: "20px" }}>
                  5. Data disclosure sharing{" "}
                </h4>
                <p style={{ margin: 0, padding: 0 }}>
                  Sysivit will handle your information with strict confidence
                  and confidentiality and not distribute your information to any
                  third parties except for specific explicit consent cases:
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    &#x2022;
                    <b> Legal Obligations: </b>
                    If we are obliged by the law, or by responding to lawful
                    requests of public authorities, to disclose information, we
                    may do so.
                  </li>
                  <li>
                    &#x2022;
                    <b> Service Providers: </b>
                    For the services provided by such service providers who we
                    find trustworthy in protecting the confidentiality of such
                    information.
                  </li>
                </ul>

                <h4 style={{ paddingTop: "20px" }}>6. Children's Privacy </h4>
                <p>
                  Our website and services are not intended for children under
                  13. We do not knowingly collect personal information from
                  children. If we become aware that we have collected personal
                  information from a child under 13 without parental consent, we
                  will take steps to delete that information.
                </p>
                <p>
                  If you are a parent or guardian and believe that your child
                  has provided us with personal information, please contact us
                  at info@sysivit.com.
                </p>

                <h4 style={{ paddingTop: "20px" }}>7. Your Rights</h4>
                <p style={{ margin: 0, padding: 0 }}>
                  You have the following rights regarding your personal
                  information under the Federal Trade Commission Act (FTC Act)
                  of the the USA:
                </p>
                <ul style={{ paddingLeft: "20px", paddingBottom: "20px" }}>
                  <li>
                    &#x2022;
                    <b> Access: </b>
                    You have the right to request access to the personal
                    information we hold about you.
                  </li>
                  <li>
                    &#x2022;
                    <b> Correction: </b>
                    You can request corrections to any inaccurate or incomplete
                    personal information.
                  </li>
                  <li>
                    &#x2022;
                    <b> Erasure: </b>
                    You have other rights under data protection laws in relation
                    to the information we hold about you. You have the right in
                    certain circumstances to have personal information deleted.
                  </li>
                </ul>
                <p>
                  To instruct us or if you have any queries in relation to these
                  rights, please contact us using the information below.
                </p>

                <h4 style={{ paddingTop: "20px" }}>
                  8. Cookies and Tracking Technologies
                </h4>
                <p>
                  Our site may use cookies and other tracking technologies to
                  enhance your experience of our website, understand the use of
                  our website, and provide advertisements regarding goods and/or
                  services of likely interest to you. Cookies are small text
                  files placed on your device to help us identify you and record
                  your preferences. You can manage your cookie preferences by
                  adjusting your browser settings.
                </p>
                <p>
                  Sysivit reserves the right to modify or update this Privacy
                  Policy at its discretion. All changes become effective
                  immediately upon posting here. We encourage you to check this
                  policy periodically for updates.
                </p>

                <h4 style={{ paddingTop: "20px" }}>9. Contact Us</h4>
                <p>
                  If you have any questions, concerns, or requests regarding
                  this Privacy Policy or our privacy practices, feel free to
                  contact us at info@sysivit.com.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogPost;
