import React from "react";
import PrivacyPost from "../components/privacydetails/PrivacyPost";
import Privacy from "../components/privacydetails/Privacy";
import { Helmet } from "react-helmet";

function BlogDetailsPage() {
  return (
    <main>
      <Helmet>
        <title>Understanding Our Privacy Policy | Sysivit</title>
        <meta
          name="description"
          content="Find out how Sysivit safeguards the personal information of its website visitors through this privacy policy."
        />
      </Helmet>
      <Privacy />
      <PrivacyPost />
    </main>
  );
}

export default BlogDetailsPage;
