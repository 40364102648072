import React from "react";

function BlogPost() {
  return (
    <section className="blog-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="standard-blog-item mb-50">
              <div className="standard-blog-content blog-details-content">
                <p>
                  Welcome to Sysivit, the firm you can trust for outstanding pet
                  support in the the USA. When using the services or accessing the
                  contents made available to you and owned by Sysivit through
                  its website, you agree that you have read, understood, and
                  agreed to be legally bound by these terms. By using our
                  services or products, you accept that these terms and
                  conditions shall form the basis of your obligations under this
                  contract. Hence, if you do not approve, then do not use it.
                </p>

                <h4 style={{ paddingTop: "20px" }}>1. Offered Services</h4>
                <p style={{ margin: 0, padding: 0 }}>
                  Sysivit is focused on providing you with high-quality pet
                  maintenance and support services including:
                </p>
                <ul
                  style={{
                    paddingLeft: "20px",
                    listStyleType: "disc",
                    listStylePosition: "outside",
                    margin: 0,
                  }}
                >
                  <li style={{ marginBottom: "5px" }}>&#x2022; Pet Sitting</li>
                  <li style={{ marginBottom: "5px" }}>&#x2022; Dog Walking</li>
                  <li style={{ marginBottom: "5px" }}>&#x2022; Pet Grooming</li>
                  <li style={{ marginBottom: "5px" }}>
                    &#x2022; Training Classes
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    &#x2022; Pet Transportation
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    &#x2022; Pet Nutrition Counseling
                  </li>
                </ul>

                <h4 style={{ paddingTop: "20px" }}>
                  2. Client Responsibilities{" "}
                </h4>
                <p style={{ margin: 0, padding: 0 }}>
                  As a client, you agree to:
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    {" "}
                    &#x2022; Provide accurate and complete information
                    concerning your pet's needs and requirements.
                  </li>
                  <li>
                    {" "}
                    &#x2022; Ensure that your pet is strong and well-vaccinated
                    before engaging our services.
                  </li>
                  <li>
                    {" "}
                    &#x2022; Notify us of any behavioral problems or special
                    needs concerning your pet.
                  </li>
                </ul>

                <h4 style={{ paddingTop: "20px" }}>3. Payment Terms</h4>
                <p>
                  Customers are expected to pay for the services provided
                  through the agreed rate with the client at the time of
                  soliciting service. Payment is accepted in cash only.
                </p>

                <h4 style={{ paddingTop: "20px" }}>4. Cancellation Policy</h4>
                <p>
                  We understand that plans change. Clients may cancel scheduled
                  services with at least 24 hours’ notice. If a client cancels
                  within less than 24 hours before the service, they will be
                  charged a cancellation fee.
                </p>

                <h4 style={{ paddingTop: "20px" }}>
                  5. Limitation of Liability{" "}
                </h4>
                <p>
                  While we strive to provide the ideal maintenance support for
                  your pets, Sysivit cannot accept any liability for injury,
                  loss, or damage that your pet may suffer while in our
                  attention, except when this arises from gross negligence or
                  willful misconduct by Sysivit. It is recommended that clients
                  consider insurance cover for their pets.
                </p>

                <h4 style={{ paddingTop: "20px" }}>
                  6. Confidentiality and Data Protection{" "}
                </h4>
                <p style={{ margin: 0, padding: 0 }}>
                  Sysivit will safeguard your personal information in compliance
                  with the Federal Trade Commission Act (FTC Act) of the the USA.
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    &#x2022;
                    <b> Information We Collect: </b>
                    To perform the duties of our service, we may collect data
                    relating to you, which can be, for example, name and contact
                    details together with the data about your pets.
                  </li>
                  <li>
                    &#x2022;
                    <b> Use of Information: </b>
                    We will use your information only to provide our services
                    and make your experience with us richer.{" "}
                  </li>
                  <li>
                    &#x2022;
                    <b> Data Sharing: </b>
                    Sysivit respects your privacy. We will not share, sell, or
                    rent your personal data to any third parties without your
                    explicit consent except where required by law or necessary
                    to protect our rights or the safety of others.
                  </li>
                </ul>

                <h4 style={{ paddingTop: "20px" }}>7. Changes to Terms</h4>
                <p>
                  Sysivit shall have the right to alter these terms and
                  conditions as and when it may so decide. All variations become
                  effective immediately upon posting on our website or notifying
                  you directly. Your continued use of the services after any
                  variation will be deemed as your acceptance of the latest
                  terms.
                </p>

                <h4 style={{ paddingTop: "20px" }}>8. Governing Law </h4>
                <p>
                  These terms and conditions shall be governed by and construed
                  in accordance with the laws of the USA. Any dispute arising out of
                  or related to these terms shall be submitted to the exclusive
                  jurisdiction of the courts in the USA.
                </p>

                <h4 style={{ paddingTop: "20px" }}>9. Severability</h4>
                <p>
                  If any provision of these terms is found by a court of
                  competent jurisdiction to be invalid or unenforceable, such
                  provision shall be limited or eliminated to the minimum extent
                  necessary and the remaining provisions of these Terms shall
                  remain in full force and effect.
                </p>

                <h4 style={{ paddingTop: "20px" }}>10. Contact Information</h4>
                <p>
                  Questions about these Terms and Conditions or our services
                  should be directed to info@sysivit.com.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogPost;
