import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

function BreederAdoption() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };
  return (
    <section className="inner-breeder-area breeder-bg">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-4 col-lg-9 col-md-12 col-sm-9">
            <div className="adoption-content">
              <h5 className="sub-title">Tailored Pet Wellness </h5>
              <h2 className="title">
                Expert Pet <span>Support</span> You Can Trust
              </h2>
              <p>
                Sysivit offers professional and reliable pet support from our
                experienced staff. Whether you need pet training advice,
                well-being management, or personal grooming, you can count on
                us.
              </p>
              <div className="adoption-list">
                <ul>
                  <li>
                    <i className="flaticon-tick" />
                    Comprehensive Services
                  </li>
                  <li>
                    <i className="flaticon-tick" />
                    Trustworthy Team
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-9 col-md-12 col-sm-9">
            <div className="breeder-info-wrap">
              <div className="row no-gutters justify-content-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <Slider className="breeder-active slick" {...settings}>
                    <div className="breeder-item">
                      <div>
                        <img
                          // style={{ height: "490px" }}
                          src="/img/images/breeder_img01.jpg"
                          alt="Breeder Image 1"
                        />
                      </div>
                    </div>
                    <div className="breeder-item">
                      <div>
                        <img
                          src="/img/images/breeder_img02.jpg"
                          alt="Breeder Image 2"
                        />
                      </div>
                    </div>
                    <div className="breeder-item">
                      <div>
                        <img
                          src="/img/images/breeder_img03.jpg"
                          alt="Breeder Image 3"
                        />
                      </div>
                    </div>
                    <div className="breeder-item">
                      <div>
                        <img
                          src="/img/images/breeder_img04.jpg"
                          alt="Breeder Image 4"
                        />
                      </div>
                    </div>
                    <div className="breeder-item">
                      <div>
                        <img
                          src="/img/images/breeder_img05.jpg"
                          alt="Breeder Image 5"
                        />
                      </div>
                    </div>
                  </Slider>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div
                    className="breed-services-info"
                    style={{
                      backgroundImage: 'url("/img/bg/breed_services_bg.jpg")',
                    }}
                  >
                    <h5 className="sub-title">Exceptional Pet Vitality </h5>
                    <h3 className="title">
                      Personalized Support for Every Pet
                    </h3>
                    <p>
                      Sysivit’s expert pet specialists ensure that your pets
                      receive personalized support concerning their unique
                      needs.
                    </p>
                    <Link to="/services" className="btn">
                      Services <img src="/img/icon/w_pawprint.png" alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default BreederAdoption;
