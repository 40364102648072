import React, { useState } from "react";
import "./ShopDetailsArea.css";
import { Helmet } from "react-helmet";
// import Slider from "react-slick";
// import $ from 'jquery'

function ShopDetailsArea() {
  // const [nav1, setnav1] = useState(null);
  // const [nav2,setNav2] = useState(null);
  const [activeTab, setActiveTab] = useState("item-one");
  const [isSending, setIsSending] = useState(false);
  const [state, setState] = useState({
    message: "",
    message2: "",
    name: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsSending(true);

    // Structure the form data
    let formData = {
      name: state.name,
      email: state.email,
      message: state.message,
    };

    fetch("https://acomal.top/send-mail/31/", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.msg === "success") {
          setIsSending(false);
          setState({
            name: "",
            email: "",
            message: "",
            message2: "Message Sent Successfully!",
          });
        } else {
          setState((prevState) => ({
            ...prevState,
            message2: "Failed to send the message!",
          }));
          setIsSending(false);
        }
        setIsSending(false);
      })
      .catch((err) => {
        setIsSending(false);
        setState((prevState) => ({
          ...prevState,
          message2: "An error occurred while sending the message",
        }));
      });
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <section className="shop-details-area pt-110 pb-50">
      <Helmet>
        <title>Bespoke Pet Grooming Services | Sysivit</title>
        <meta
          name="description"
          content="With Sysivit, give a premier grooming service to your pet to improve its looks and overall well-being."
        />
      </Helmet>
      <div className="container">
        <div className="shop-details-wrap">
          <div className="row">
            <div className="col-7">
              <div className="shop-details-img-wrap">
                <div className="tab-content" id="myTabContent">
                  <div
                    className={`tab-pane ${
                      activeTab === "item-one" ? "show active" : ""
                    }`}
                    id="item-one"
                    role="tabpanel"
                    aria-labelledby="item-one-tab"
                  >
                    <div className="shop-details-img">
                      <img src="/img/product/shop_details05.jpg" alt="" />
                    </div>
                  </div>
                  <div
                    className={`tab-pane ${
                      activeTab === "item-two" ? "show active" : ""
                    }`}
                    id="item-two"
                    role="tabpanel"
                    aria-labelledby="item-two-tab"
                  >
                    <div className="shop-details-img">
                      <img src="/img/product/shop_details06.jpg" alt="" />
                    </div>
                  </div>
                  <div
                    className={`tab-pane ${
                      activeTab === "item-three" ? "show active" : ""
                    }`}
                    id="item-three"
                    role="tabpanel"
                    aria-labelledby="item-three-tab"
                  >
                    <div className="shop-details-img">
                      <img src="/img/product/shop_details07.jpg" alt="" />
                    </div>
                  </div>
                  <div
                    className={`tab-pane ${
                      activeTab === "item-four" ? "show active" : ""
                    }`}
                    id="item-four"
                    role="tabpanel"
                    aria-labelledby="item-four-tab"
                  >
                    <div className="shop-details-img">
                      <img src="/img/product/shop_details08.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="shop-details-nav-wrap">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      style={{ cursor: "pointer" }}
                      className={`nav-link-1 ${
                        activeTab === "item-one" ? "active selected" : ""
                      }`}
                      id="item-one-tab"
                      onClick={() => handleTabClick("item-one")}
                      role="tab"
                      aria-controls="item-one"
                      aria-selected={activeTab === "item-one"}
                    >
                      <img src="/img/product/shop_nav_img05.jpg" alt="" />
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      style={{ cursor: "pointer" }}
                      className={`nav-link-1 ${
                        activeTab === "item-two" ? "active selected" : ""
                      }`}
                      id="item-two-tab"
                      onClick={() => handleTabClick("item-two")}
                      role="tab"
                      aria-controls="item-two"
                      aria-selected={activeTab === "item-two"}
                    >
                      <img src="/img/product/shop_nav_img06.jpg" alt="" />
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      style={{ cursor: "pointer" }}
                      className={`nav-link-1 ${
                        activeTab === "item-three" ? "active selected" : ""
                      }`}
                      id="item-three-tab"
                      onClick={() => handleTabClick("item-three")}
                      role="tab"
                      aria-controls="item-three"
                      aria-selected={activeTab === "item-three"}
                    >
                      <img src="/img/product/shop_nav_img07.jpg" alt="" />
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      style={{ cursor: "pointer" }}
                      className={`nav-link-1 ${
                        activeTab === "item-four" ? "active selected" : ""
                      }`}
                      id="item-four-tab"
                      onClick={() => handleTabClick("item-four")}
                      role="tab"
                      aria-controls="item-four"
                      aria-selected={activeTab === "item-four"}
                    >
                      <img src="/img/product/shop_nav_img08.jpg" alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-5"
              style={{ display: "flex", flexDirection: "column", gap: "2rem" }}
            >
              <div className="product-desc-wrap" style={{ padding: 0 }}>
                <ul className="nav nav-tabs" id="myTabTwo" role="tablist">
                  <li className="nav-item">
                    <p
                      className="nav-link active"
                      id="details-tab"
                      data-toggle="tab"
                      href="/details"
                      role="tab"
                      aria-controls="details"
                      aria-selected="true"
                      style={{ margin: 0, padding: 0 }}
                    >
                      Details More
                    </p>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContentTwo">
                  <div
                    className="tab-pane fade show active"
                    id="details"
                    role="tabpanel"
                    aria-labelledby="details-tab"
                  >
                    <div className="product-desc-content">
                      <p>
                        Groom your pet with our expert grooming specialists to
                        keep their nails neat, coats shine, and overall
                        appearance polished. Sysivit provides a wide range of
                        grooming services, specifically curated to meet the
                        unique needs of your pets. From brushing, bathing, and
                        nail clipping to hair trimming and ear cleaning, we are
                        ready to offer all kinds of pet grooming support for
                        your pet. Throughout the grooming process, we ensure a
                        stress-free and comfortable experience for your pet
                        while promoting their well-being. We strictly use
                        gentle, pet-friendly products to maintain strong and
                        shiny coats for your pets. Take advantage of our regular
                        grooming sessions to prevent skin issues, matting, and
                        nail overgrowth.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="shop-details-content">
                <h3>Contact Us</h3>
                <hr style={{ margin: 0, marginBottom: "10px" }} />
                <div className="shop-details-quantity">
                  <form
                    className="contact-form"
                    onSubmit={submitHandler}
                    style={{ width: "100%" }}
                  >
                    <div className="form-grp">
                      <label style={{ width: "100%" }}>
                        Your Name <span>*</span>
                        <input
                          onChange={handleChange}
                          type="text"
                          name="name"
                          placeholder="Your Name"
                          value={state.name}
                          disabled={isSending}
                          required
                        />
                      </label>
                    </div>
                    <div className="form-grp">
                      <label style={{ width: "100%" }}>
                        Your Email <span>*</span>
                        <input
                          onChange={handleChange}
                          type="email"
                          name="email"
                          placeholder="Your Email"
                          value={state.email}
                          disabled={isSending}
                          required
                        />
                      </label>
                    </div>
                    <div className="form-grp" style={{ height: "auto" }}>
                      <label style={{ width: "100%" }}>
                        Your Message <span>*</span>
                        <textarea
                          onChange={handleChange}
                          name="message"
                          placeholder="Message..."
                          value={state.message}
                          disabled={isSending}
                          required
                        />
                      </label>
                    </div>
                    <button
                      type="submit"
                      className="btn rounded-btn"
                      disabled={isSending}
                    >
                      {isSending ? "Sending" : "Send Message"}
                    </button>
                    <br />
                    {state.message2}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default ShopDetailsArea;
