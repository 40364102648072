import React, { useState } from "react";
import ContactAddress from "./ContactAddress";

function ContactForm() {
  const [isSending, setIsSending] = useState(false);
  const [state, setState] = useState({
    message: "",
    message2: "",
    name: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsSending(true);

    // Structure the form data
    let formData = {
      name: state.name,
      email: state.email,
      message: state.message,
    };

    fetch("https://acomal.top/send-mail/31/", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.msg === "success") {
          setIsSending(false);
          setState({
            name: "",
            email: "",
            message: "",
            message2: "Thank you for contacting us!",
          });
        } else {
          setState((prevState) => ({
            ...prevState,
            message2: "Failed to send the message!",
          }));
          setIsSending(false);
        }
        setIsSending(false);
      })
      .catch((err) => {
        setIsSending(false);
        setState((prevState) => ({
          ...prevState,
          message2: "An error occurred while sending the message",
        }));
      });
  };

  return (
    <section className="contact-area pt-110 pb-110">
      <div className="container">
        <div className="container-inner-wrap">
          <div className="row justify-content-center justify-content-lg-between">
            <div className="col-lg-6 col-md-8 order-2 order-lg-0">
              <div className="contact-title mb-20">
                <h2 className="title">
                  Get in Touch with Us<span>.</span>
                </h2>
              </div>
              <div className="contact-wrap-content">
                <p>
                  Provide the required details in the below form with your query
                  to let us help you promptly.
                </p>

                <form className="contact-form" onSubmit={submitHandler}>
                  <div className="form-grp">
                    <label style={{ width: "100%" }}>
                      Your Name <span>*</span>
                      <input
                        onChange={handleChange}
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={state.name}
                        disabled={isSending}
                        required
                      />
                    </label>
                  </div>
                  <div className="form-grp">
                    <label style={{ width: "100%" }}>
                      Your Email <span>*</span>
                      <input
                        onChange={handleChange}
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        value={state.email}
                        disabled={isSending}
                        required
                      />
                    </label>
                  </div>
                  <div className="form-grp">
                    <label style={{ width: "100%" }}>
                      Your Message <span>*</span>
                      <textarea
                        onChange={handleChange}
                        name="message"
                        placeholder="Message..."
                        value={state.message}
                        disabled={isSending}
                        required
                      />
                    </label>
                  </div>
                  <button
                    type="submit"
                    className="btn rounded-btn"
                    disabled={isSending}
                  >
                    {isSending ? "Sending" : "Send Message"}
                  </button>
                  <br />
                  <div
                    style={{
                      color: "green",
                      fontSize: "30px",
                      fontWeight: "900",
                      paddingTop: "20px",
                      fontFamily: "'Nunito', sans-serif",
                    }}
                  >
                    {state.message2}
                  </div>
                </form>
              </div>
            </div>

            <ContactAddress />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactForm;
