"use client";

import React from "react";
import MainSlider from "../components/MainSlider";
import Find from "../components/Find";
import Faq from "../components/Faq";
import AdoptionShop from "../components/AdoptionShop";
import BlogsHome from "../components/BlogsHome";
import { useEffect } from "react";
import BreederAdoption from "../components/breeder/BreederAdoption";
import { Helmet } from "react-helmet";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      <Helmet>
        <title>A Trusted Pet Support Partner | Sysivit</title>
        <meta
          name="description"
          content="If you are looking for a reliable pet support partner for your furry friend, trust our exceptional services for long-term results."
        />
      </Helmet>
     
      <MainSlider />
      <Find />
      {/* <Adoption /> */}
      <BreederAdoption />
      <Faq afterElment="faq-area" />
      {/* <Brand /> */}
      <AdoptionShop />
      {/* <Testimonial /> */}
      <BlogsHome />
      {/* <Newsletter /> */}
    </main>
  );
}

export default Home;
