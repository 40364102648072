import React from "react";

function Find() {
  return (
    <div className="find-area">
      <div className="container custom-container">
        <div className="row">
          <div className="col-12">
            <form>
              <div className="find-wrap">
                <div className="location">
                  {/* <i className="flaticon-location" />
                  <input type="text" defaultValue="Enter City, State. or Zip" /> */}
                </div>
                <div className="find-category">
                  <ul>
                    <li>
                      <a style={{ cursor: "default" }}>
                        <i className="flaticon-dog" />
                        Pet Support
                      </a>
                    </li>
                    <li>
                      <a style={{ cursor: "default" }}>
                        <i className="flaticon-dove" />
                        Trusted Expertise
                      </a>
                    </li>
                    <li>
                      <a style={{ cursor: "default" }}>
                        <i className="flaticon-happy" />
                        {/* Personalized Attention */}
                        Personalized Support
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Find;
