"use client";

import React, { useEffect, useState } from "react";

const TopBanner = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [bannerSettings, setBannerSettings] = useState({
    is_active: false,
    link: "",
  });

  useEffect(() => {
    // Fetch current banner settings
    fetch("/get-image-url-sysivit")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        setBannerSettings(data);
      });
    // Handle window resize
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial window width

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { is_active, link } = bannerSettings;

  if (is_active == "false") {
    return null;
  }

  const handleRedirect = () => {
    if (link) {
      window.open(link, "_blank");
    }
  };

  const style = {
    background: `linear-gradient(0deg, rgba(2, 0, 1, 0.9), rgba(2, 0, 1, 0.9)), url()`,
    width: "100%",
    display: is_active === "true" ? "block" : "none",
    height: "100vh", // or any other height you need
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div id="bgBanner" onClick={handleRedirect} style={style}>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          alignItems: "center",
          height: "100%",
          display: "flex",
          textAlign: "center",
        }}
      >
        <div style={{ width: "100%" }} className="text-center">
          <h2
            style={{
              fontWeight: "400",
              lineHeight: "40px",
              color: "#fff",
              fontSize: "40px",
              textTransform: "uppercase",
              marginBottom: "30px",
            }}
          >
            Please Click Here to Continue
          </h2>
          <div>
            <a
              className="vs-btn style13"
              href="#"
              style={{
                background: "#1eb034",
                color: "rgb(255, 255, 255)",
                padding: "20px 10%",
                fontOpticalSizing: "auto",
                fontWeight: 600,
                fontSize: "30px",
                fontStyle: "normal",
                cursor: "pointer",
                borderRadius: "5px",
                transition: "transform 0.3s, background-color 0.3s",
                transform: "scale(1)",
                textDecoration: "none",
              }}
            >
              CLICK TO CONTINUE
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
