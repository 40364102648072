import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import "./css/bootstrap.min.css";
import "./css/fontawesome-all.min.css";
import "./css/style.css";
import "./css/responsive.css";
import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"), function () {
  $("#preloader").delay(0).fadeOut();
});
