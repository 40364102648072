import React from "react";

function BlogPost() {
  return (
    <section className="blog-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="standard-blog-item mb-50">
              <div className="standard-blog-content blog-details-content">
                <p>
                  Sysivit maintains your privacy and transparency in how we
                  collect and use information about you. This Cookies Policy
                  describes what cookies are, how we use them on our website,
                  and what choices you have regarding the management of your
                  cookies.
                </p>

                <h4 style={{ paddingTop: "20px" }}>1. What Are Cookies?</h4>
                <p style={{ margin: 0, padding: 0 }}>
                  Cookies are the small text files, stored on your device
                  (computer, smartphone, or tablet), when you are visiting the
                  website. The cookies help the website recognize the device and
                  then store certain information about the preferences or past
                  actions taken by you. Cookies are categorized into the
                  following types:
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    &#x2022;
                    <b> Essential Cookies: </b>
                    These cookies are absolutely necessary for the website to
                    run. These help with features like navigation to different
                    pages and access to secured areas of the website.
                  </li>
                  <li>
                    {" "}
                    &#x2022;
                    <b> Performance Cookies: </b>
                    These cookies are used to collect information regarding how
                    the visitors of a website interact with the site, for
                    example, what pages have been accessed more often. This will
                    be helpful for us in analyzing user behavior and optimizing
                    the performance of our website.
                  </li>
                  <li>
                    {" "}
                    &#x2022;
                    <b> Functional Cookies: </b>
                    These enable functionalities of presenting you with your
                    specific preference, such as language or interface
                    customization.
                  </li>
                  <li>
                    {" "}
                    &#x2022;
                    <b> Targeting/Advertising Cookies: </b>
                    These cookies help us to target advertisements to you and
                    your interests, and prevent the same advert from showing to
                    you time and time again. In addition, they may be used in
                    determining site effectiveness as well as serving
                    advertising.
                  </li>
                </ul>

                <h4 style={{ paddingTop: "20px" }}>2. Our Use of Cookies</h4>
                <p style={{ margin: 0, padding: 0 }}>
                  Sysivit uses cookies to achieve any of the following:
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    &#x2022;
                    <b> Improve the Experience of Our Users: </b>
                    We use cookies to remember your preferences and settings
                    that enhance your experience on our website.
                  </li>
                  <li>
                    &#x2022;
                    <b> Analyzing Traffic of Our Website: </b>
                    Performance cookies enable us to analyze the way visitors
                    move through the website so we can better identify where the
                    improvements should be made.
                  </li>
                  <li>
                    &#x2022;
                    <b> To Deliver Relevant Content: </b>
                    The targeting cookies enable us to deliver content and
                    advertisements that are more relevant to you, based on past
                    browsing history.
                  </li>
                </ul>

                <h4 style={{ paddingTop: "20px" }}>3. Third-Party Cookies</h4>
                <p>
                  In addition to the cookies that we place, our website uses
                  cookies from third-party service providers to assist us in
                  understanding how you use our website. These might include
                  analytics services and advertising partners, so as third-party
                  cookies, these are out of our control; this cookie's use is
                  covered under relevant third parties' privacy policy.
                </p>

                <h4 style={{ paddingTop: "20px" }}>4. Control of Cookies</h4>
                <p>
                  You can control your cookie preferences. Many web browsers
                  have settings to manage cookies. You can accept, reject, or
                  delete existing cookies, or set your browser to notify you
                  when cookies are in use.
                </p>

                <h4 style={{ paddingTop: "20px" }}>5. Your Consent</h4>
                <p>
                  By utilizing our website, you give consent to the use of
                  cookies as outlined in this Cookies Policy. You may withdraw
                  this consent at any given moment by changing your browser
                  settings to refuse cookies. Disabling cookies may affect your
                  ability to use all functionalities on our website.
                </p>

                <h4 style={{ paddingTop: "20px" }}>
                  6. Changes to This Cookies Policy
                </h4>
                <p>
                  Sysivit may update this Cookies Policy at any time. All
                  changes will have an immediate effect upon the posting of such
                  changes on our website. We will be glad to have you frequently
                  visit this policy to find out how we use cookies.
                </p>

                <h4 style={{ paddingTop: "20px" }}>7. Contact Us</h4>
                <p>
                  If you have concerns or questions regarding our Cookies Policy
                  or how we use cookies, you may contact us at info@sysivit.com.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogPost;
