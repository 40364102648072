import React from "react";
import { Link } from "react-router-dom";

function AdoptionShop() {
  return (
    <section className="adoption-shop-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon">
                <img src="/img/icon/pawprint.png" alt="" />
              </div>
              <h5 className="sub-title">Our Services</h5>
              <h2 className="title">Specialized Pet Wellness Services</h2>
              {/* <p>
                The best overall dog DNA test is Embark Breed &amp; Health Kit
                (view at Chewy), which provides you with a breed brwn and
                information Most dogs
              </p> */}
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="/img/product/adoption_shop_thumb01.jpg" alt="" />
                <Link to="/Dog-Walking" className="btn">
                  Dog Walking <img src="/img/icon/w_pawprint.png" alt="" />
                </Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title">
                  <Link to="/Dog-Walking">Dog Walking</Link>
                </h4>
                <p>
                  This service by Sysivit is the perfect solution for busy dog
                  owners who miss the opportunity to walk their dogs regularly.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="/img/product/adoption_shop_thumb02.jpg" alt="" />
                <Link to="/Pet-Grooming" className="btn">
                  Pet Grooming <img src="/img/icon/w_pawprint.png" alt="" />
                </Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title">
                  <Link to="/Pet-Grooming">Pet Grooming</Link>
                </h4>
                <p>
                  Groom your pet with our expert grooming specialists to keep
                  their nails neat, coats shine, and overall appearance
                  polished.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="/img/product/adoption_shop_thumb03.jpg" alt="" />
                <Link to="/Pet-Nutrition-Counseling" className="btn">
                  Pet Nutrition Counseling
                  <img src="/img/icon/w_pawprint.png" alt="" />
                </Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title">
                  <Link to="/Pet-Nutrition-Counseling">
                    Pet Nutrition Counseling
                  </Link>
                </h4>
                <p>
                  Our tailored Nutrition Counselling Service targets to promote
                  the overall well-being of your pet.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="/img/product/adoption_shop_thumb04.jpg" alt="" />
                <Link to="/Pet-Sitting" className="btn">
                  Pet Sitting <img src="/img/icon/w_pawprint.png" alt="" />
                </Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title">
                  <Link to="/Pet-Sitting">Pet Sitting</Link>
                </h4>
                <p>
                  Get complete peace of mind by availing our pet sitting
                  service, which ensures your pet is in safe hands when you are
                  away from home.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="/img/product/adoption_shop_thumb05.jpg" alt="" />
                <Link to="/Pet-Transportation" className="btn">
                  Pet Transportation
                  <img src="/img/icon/w_pawprint.png" alt="" />
                </Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title">
                  <Link to="/Pet-Transportation">Pet Transportation</Link>
                </h4>
                <p>
                  Are you looking for a service that helps transport your pets
                  comfortably and safely to a desired location? If so, you can
                  rely on Sysivit’s pet transportation service.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="/img/product/adoption_shop_thumb06.jpg" alt="" />
                <Link to="/Training-Classes" className="btn">
                  Training Classes <img src="/img/icon/w_pawprint.png" alt="" />
                </Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title">
                  <Link to="/Training-Classes">Training Classes</Link>
                </h4>
                <p>
                  Sysivit has designed these classes to improve your pets'
                  behavior and foster a stronger connection between pets and
                  their owners.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdoptionShop;
