import React from "react";
import BlogBreadcumb from "../components/blogs/BlogBreadcumb";
import BlogContent from "../components/blogs/BlogContent";
import { Helmet } from "react-helmet";

function BlogPage() {
  return (
    <main>
      <Helmet>
        <title>Explore Our Blogs | Sysivit</title>
        <meta
          name="description"
          content="Discover various pet support tips, strategies, and management techniques in this section. Stay informed about the latest pet support trends across the USA."
        />
      </Helmet>
      <BlogBreadcumb />
      <BlogContent />
    </main>
  );
}
export default BlogPage;
