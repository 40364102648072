import React from "react";
import AboutPost from "../components/aboutdetails/AboutPost";
import About from "../components/aboutdetails/About";
import { Helmet } from "react-helmet";

function BlogDetailsPage() {
  return (
    <main>
      <Helmet>
        <title>Your Pet’s Wellness Buddy | Sysivit</title>
        <meta
          name="description"
          content="Learn about our mission, vision, and values to know how passionate Sysivit is about delivering outstanding pet support in the USA."
        />
      </Helmet>
      <About />
      <AboutPost />
    </main>
  );
}

export default BlogDetailsPage;
