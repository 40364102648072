import React from "react";
import { Link } from "react-router-dom";

function BlogContent() {
  return (
    <section className="standard-blog-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="standard-blog-item">
              <div className="standard-blog-thumb">
                <Link to="/How-to-Create-a-Pet-Friendly-Home-Environment">
                  <img src="/img/blog/blog_thumb01.jpg" alt="" />
                </Link>
              </div>
              <div className="standard-blog-content">
                <div className="blog-post-meta"></div>
                <h2 className="title">
                  <Link to="/How-to-Create-a-Pet-Friendly-Home-Environment">
                    How to Create a Pet-Friendly Home Environment
                  </Link>
                </h2>
                <p>
                  Are you buckling up to bring your first pet home? Welcoming
                  the first pet can be exciting, blissful, and overwhelming at
                  the same time. If you are wondering why it can be
                  overwhelming, ask any pet owner about the time they first
                  brought their pet home. Having a pet is a joyful ride. But, it
                  only becomes joyous when both you and your pet live in a
                  balancing and comforting environment.
                </p>
                <Link
                  to="/How-to-Create-a-Pet-Friendly-Home-Environment"
                  className="read-more"
                >
                  Read More <img src="/img/icon/pawprint.png" alt="" />
                </Link>
              </div>
            </div>
            <div className="standard-blog-item">
              <div className="standard-blog-thumb">
                <Link to="/The-Role-of-Pets-in-Mental-Well-Being">
                  <img src="/img/blog/blog_thumb02.jpg" alt="" />
                </Link>
              </div>
              <div className="standard-blog-content">
                <h2 className="title">
                  <Link to="/The-Role-of-Pets-in-Mental-Well-Being">
                    The Role of Pets in Mental Well-Being
                  </Link>
                </h2>
                <p>
                  Just like the saying goes, pets are humans’ closest friends.
                  This is not just a saying, but concrete evidence of this fact
                  can be seen anywhere where you can observe the endearing bond
                  between a pet owner and his or her pet. Across this globe,
                  people find love, joy, and connection with their pets. Slowly,
                  they become a significant part of your family. The first time
                  when you made the decision to bring a pet home gave you so
                  much joy.
                </p>
                <Link
                  to="/The-Role-of-Pets-in-Mental-Well-Being"
                  className="read-more"
                >
                  Read More <img src="/img/icon/pawprint.png" alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogContent;
