import React from "react";

function BlogPost() {
  return (
    <section className="blog-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="standard-blog-item mb-50">
              <div className="standard-blog-thumb">
                <img src="/img/blog/blog_thumb04.jpg" alt="" />
              </div>
              <div className="standard-blog-content blog-details-content">
                <h4 className="title">Our Journey</h4>
                <p style={{ paddingBottom: "20px" }}>
                  Sysivit is a promising pet support company that was founded
                  with the mission to improve the lives of pets and their
                  owners. We are passionate about pet management and focus on
                  providing high-quality pet support at affordable prices. A
                  wide variety of services are specifically designed for the
                  requirements of your pets. Our committed team consists of
                  passionate animal lovers dedicated to providing excellent pet
                  support, ranging from grooming to pet sitting, pet
                  transportation, nutrition counseling, and many more. We aspire
                  to be a popular and trusted pet wellness partner for pet
                  parents in the USA.
                </p>

                <h4>Our Mission</h4>
                <p>
                  We intend to make every pet’s life as enriching as possible
                  with expert support.
                </p>

                <h4>Our Vision</h4>
                <p>
                  We look forward to becoming a reliable pet support network in
                  the USA.
                </p>

                <h4>Our Values</h4>
                <p>
                  We value integrity and compassion in making us a promising pet
                  support company.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogPost;
