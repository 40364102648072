import React from "react";
import ContactBreadCumb from "../components/contacts/ContactBreadCumb";
import ContactForm from "../components/contacts/ContactForm";
import { Helmet } from "react-helmet";

function ContactPage() {
  return (
    <main>
      <Helmet>
        <title>Reach Out to Us | Sysivit</title>
        <meta
          name="description"
          content="Feel free to get in touch with us if you have any queries regarding pet support. Our team is always available to assist you."
        />
      </Helmet>
      <ContactBreadCumb />
      <ContactForm />
      {/* <NewsLetter/> */}
    </main>
  );
}

export default ContactPage;
