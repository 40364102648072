import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";
import Home from "./pages/Home";
import ServiceList from "./pages/ServiceList";
import ContactPage from "./pages/ContactPage";
import BlogPage from "./pages/BlogPage";
import BlogDetailsPage from "./pages/BlogDetailsPage";
import BlogDetailsPage2 from "./pages/BlogDetailsPage2";
import DogWalking from "./pages/DogWalking";
import PetGrooming from "./pages/PetGrooming";
import PetNutritionCounseling from "./pages/PetNutritionCounseling";
import PetSitting from "./pages/PetSitting";
import PetTransportation from "./pages/PetTransportation";
import TrainingClasses from "./pages/TrainingClasses";
import ScrollToTopRoute from "./ScrollToTopRoute";
import NotFound from "./pages/NotFound";
import AboutPage from "./pages/AboutPage";
import TermsPage from "./pages/TermsPage";
import CookiesPage from "./pages/CookiesPage";
import PrivacyPage from "./pages/PrivacyPage";
import TopBanner from "./components/TopBanner/topBanner";

function App() {
  return (
    <div className="app">
      <Router>
        {/* Only render Header and Footer for valid routes */}
        <Switch>
          {/* Other Routes */}
          <Route exact path="/">
            <TopBanner />
            <Header />
            <ScrollToTopRoute>
              <Home />
            </ScrollToTopRoute>
            <Footer />
          </Route>
          
          <Route exact path="/services">
            <Header />
            <ScrollToTopRoute>
              <ServiceList />
            </ScrollToTopRoute>
            <Footer />
          </Route>
          <Route exact path="/contacts">
            <Header />
            <ScrollToTopRoute>
              <ContactPage />
            </ScrollToTopRoute>
            <Footer />
          </Route>
          <Route exact path="/blogs">
            <Header />
            <ScrollToTopRoute>
              <BlogPage />
            </ScrollToTopRoute>
            <Footer />
          </Route>
          <Route exact path="/How-to-Create-a-Pet-Friendly-Home-Environment">
            <Header />
            <ScrollToTopRoute>
              <BlogDetailsPage />
            </ScrollToTopRoute>
            <Footer />
          </Route>
          <Route exact path="/The-Role-of-Pets-in-Mental-Well-Being">
            <Header />
            <ScrollToTopRoute>
              <BlogDetailsPage2 />
            </ScrollToTopRoute>
            <Footer />
          </Route>
          <Route exact path="/Dog-Walking">
            <Header />
            <ScrollToTopRoute>
              <DogWalking />
            </ScrollToTopRoute>
            <Footer />
          </Route>
          <Route exact path="/Pet-Grooming">
            <Header />
            <ScrollToTopRoute>
              <PetGrooming />
            </ScrollToTopRoute>
            <Footer />
          </Route>
          <Route exact path="/Pet-Nutrition-Counseling">
            <Header />
            <ScrollToTopRoute>
              <PetNutritionCounseling />
            </ScrollToTopRoute>
            <Footer />
          </Route>
          <Route exact path="/Pet-Sitting">
            <Header />
            <ScrollToTopRoute>
              <PetSitting />
            </ScrollToTopRoute>
            <Footer />
          </Route>
          <Route exact path="/Pet-Transportation">
            <Header />
            <ScrollToTopRoute>
              <PetTransportation />
            </ScrollToTopRoute>
            <Footer />
          </Route>
          <Route exact path="/Training-Classes">
            <Header />
            <ScrollToTopRoute>
              <TrainingClasses />
            </ScrollToTopRoute>
            <Footer />
          </Route>
          <Route exact path="/about">
            <Header />
            <ScrollToTopRoute>
              <AboutPage />
            </ScrollToTopRoute>
            <Footer />
          </Route>
          <Route exact path="/cookies-policy">
            <Header />
            <ScrollToTopRoute>
              <CookiesPage />
            </ScrollToTopRoute>
            <Footer />
          </Route>
          <Route exact path="/privacy-policy">
            <Header />
            <ScrollToTopRoute>
              <PrivacyPage />
            </ScrollToTopRoute>
            <Footer />
          </Route>
          <Route exact path="/terms-and-conditions">
            <Header />
            <ScrollToTopRoute>
              <TermsPage />
            </ScrollToTopRoute>
            <Footer />
          </Route>

          {/* 404 Not Found Route */}
          <Route path="*">
            <Header />
            <NotFound />
            <Footer />
          </Route>
          <Route path="/404">
            <Header />
            <NotFound />
            <Footer />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
