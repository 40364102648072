import React from "react";
import { Link } from "react-router-dom";

function BlogsHome() {
  return (
    <section className="blog-area pt-60 pb-60">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon">
                <img src="/img/icon/pawprint.png" alt="" />
              </div>
              <h5 className="sub-title">Our Blogs</h5>
              <h2 className="title">Explore Latest News & Updates</h2>
              <p>
                Stay informed regarding the latest development in pet support,
                industry trends, and their well-being management with our blogs.
                This section keeps you updated to better equip you with the
                latest pet support tips.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="blog-post-item mb-50">
              <div className="blog-post-thumb">
                <Link to="/How-to-Create-a-Pet-Friendly-Home-Environment">
                  <img src="/img/blog/blog_post_thumb01.jpg" alt="" />
                </Link>
              </div>
              <div className="blog-post-content">
                <h3 className="title">
                  <Link to="/How-to-Create-a-Pet-Friendly-Home-Environment">
                    {" "}
                    How to Create a Pet-Friendly Home Environment
                  </Link>
                </h3>
                <p>
                  Are you buckling up to bring your first pet home? Welcoming
                  the first pet can be exciting, blissful, and overwhelming at
                  the same time.
                </p>
                <Link
                  to="/How-to-Create-a-Pet-Friendly-Home-Environment"
                  className="read-more"
                >
                  Read More <img src="/img/icon/pawprint.png" alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="blog-post-item mb-50">
              <div className="blog-post-thumb">
                <Link to="/The-Role-of-Pets-in-Mental-Well-Being">
                  <img src="/img/blog/blog_post_thumb02.jpg" alt="" />
                </Link>
              </div>
              <div className="blog-post-content">
                <h3 className="title">
                  <Link to="/The-Role-of-Pets-in-Mental-Well-Being">
                    {" "}
                    The Role of Pets in Mental Well-Being
                  </Link>
                </h3>
                <p>
                  Just like the saying goes, pets are humans’ closest friends.
                  This is not just a saying, but concrete evidence of this fact
                  can be seen anywhere where you can observe the endearing bond
                  between a pet owner and his or her pet.
                </p>
                <Link
                  to="/The-Role-of-Pets-in-Mental-Well-Being"
                  className="read-more"
                >
                  Read More <img src="/img/icon/pawprint.png" alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogsHome;
