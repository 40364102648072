import React from "react";
import { Link } from "react-router-dom";
import "../css/style.css";
import "../css/responsive.css";
// import "../css/bootstrap.min.css";
import "../css/animate.min.css";
function Footer() {
  return (
    <footer>
      <div className="footer-top-area footer-bg">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-3">
              <div className="footer-widget">
                <div className="f-logo">
                  <Link to="/">
                    <img src="/img/logo/logo.png" alt="footerlogo" />
                  </Link>
                </div>
                <div className="footer-text">
                  <p>
                    For all your pet support needs, Sysivit’s expert staff is
                    dedicated to providing exceptional assistance that promotes
                    an active lifestyle for your pets.
                  </p>
                </div>
                <div className="footer-social mt-20">
                  <ul>
                    <li>
                      <a
                        href="https://www.reddit.com/user/Sysivit/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-reddit" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://x.com/sysivit"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/@Sysivit"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-youtube" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.pinterest.com/sysivit/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-pinterest" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="footer-widget">
                <h4 className="fw-title">Quick Links</h4>
                <div className="fw-link">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    {/* <li>
                      <Link to="/shop">Shop</Link>
                    </li> */}
                    <li>
                      <Link to="/blogs">Blogs</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Contacts</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="footer-widget">
                <h4 className="fw-title">Other Links</h4>
                <div className="fw-link">
                  <ul>
                    <li>
                      <Link to="/cookies-policy">Cookies Policy</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    {/* <li>
                      <Link to="/cancellation-policy">Cancellation Policy</Link>
                    </li> */}
                    <li>
                      <Link to="/terms-and-conditions">Terms & Condition</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="footer-widget">
                <h4 className="fw-title">Contact Us</h4>
                <div className="contact-info-list">
                  <ul style={{}}>
                    <li>
                      <div className="icon">
                        <i className="fas fa-map-marker-alt" />
                      </div>
                      <div className="content">
                        <p>
                          351 W 57th St, New York,
                          <br /> NY 10019, USA
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fas fa-phone-alt" />
                      </div>
                      <div className="content">
                        <p>+1 2543673859</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fas fa-envelope-open" />
                      </div>
                      <div className="content">
                        <p>info@sysivit.com</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-shape">
          <img src="/img/images/footer_shape01.png" alt="" />
        </div>
        <div className="footer-shape shape-two">
          <img src="/img/images/footer_shape02.png" alt="" />
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="copyright-text">
                <p>
                  Copyright © 2024 <a href="/">Sysivit</a>. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
