import React from "react";
import TermPost from "../components/termdetails/TermPost";
import Terms from "../components/termdetails/Terms";
import { Helmet } from "react-helmet";

function BlogDetailsPage() {
  return (
    <main>
      <Helmet>
        <title>Terms and Conditions | Sysivit</title>
        <meta
          name="description"
          content="Review this segment to understand your obligations and rights, as well as the specific terms for using Sysivit’s pet support offerings."
        />
      </Helmet>
      <Terms />
      <TermPost />
    </main>
  );
}

export default BlogDetailsPage;
